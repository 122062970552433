import { FavoleContainer } from "./FavoleContainer";
import { Login } from "./Login";
import { safeCustomDefine } from "./safe-custom-define";

class App extends HTMLElement {
    static get tag(): string {
        return `app`;
    }
    connectedCallback() {
        // center the contents of the element
        this.style.display = "flex";
        this.style.justifyContent = "center";
        this.style.alignItems = "center";
        this.style.height = "100%";
        this.style.width = "100%";
        // center the text
        this.style.textAlign = "center";

        this.innerHTML = `
            <${FavoleContainer.tag}></${FavoleContainer.tag}>
        `;
    }
}
safeCustomDefine(App);