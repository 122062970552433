import { Favola } from "./Favola";
import { safeCustomDefine } from "./safe-custom-define";

export class FavoleContainer extends HTMLElement {
    static get tag(): string {
        return `favole-container`;
    }
    async connectedCallback() {
        this.style.width = "100%";
        this.style.display = "grid";
        this.style.gridTemplateColumns = "repeat(auto-fit, minmax(20rem, 1fr))";

        const favole = await this.fetchFavole();
        console.log({ favole });
        const stringFavole = favole.map(favola => {
            return `
                <${Favola.tag} title="${favola.title}"></${Favola.tag}>
            `;
        });
        this.innerHTML = stringFavole.join(``);
    }
    async fetchFavole() {
        // fetch files from google drive rest api
        const response: Response = await fetch(`https://script.google.com/macros/s/AKfycbw2zyOKo37xJhyLSvOu7IUzXvSJfmNURdPeLOqF0JaGzkuVrCnbkwv9Ur9IA3A6PhBx/exec`);
        const json = await response.json();
        console.log(json);
        return json;
    }
}
safeCustomDefine(FavoleContainer);