import { safeCustomDefine } from "./safe-custom-define";

export class Favola extends HTMLElement {
    static get tag(): string {
        return `favola`;
    }
    connectedCallback() {
        this.style.width = "20rem";
        const [title, reader] = this.getAttribute(`title`).split('.')[0].split(`-`);
        this.innerHTML = `
            <h1>${title}</h1>
            <p>${reader}</p>
        `;
    }
}
safeCustomDefine(Favola);