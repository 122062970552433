import { GLOBALS } from "../GLOBALS";

class PrototypeComponent extends HTMLElement {
    static get tag(): string {
        return GLOBALS.prefix;
    }
}
export const safeCustomDefine = (classElement: typeof PrototypeComponent) => {
    const tag: string = `${PrototypeComponent.tag}-${classElement.tag}`;
    Object.defineProperty(classElement, "tag", {
        get: () => tag
    });
    customElements.get(tag) || customElements.define(tag, classElement);
}